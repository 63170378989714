// src/components/AuthModal.js
import React, { useState } from 'react';
import { Modal, Button, Form, Carousel, Row, Col, Container } from 'react-bootstrap';
import { auth } from '../firebase/firebaseAuth';
import { signInWithEmailAndPassword, signInWithCredential, createUserWithEmailAndPassword, GoogleAuthProvider, linkWithPopup, sendEmailVerification, signInWithPopup } from "firebase/auth";
import googleLogo from '../assets/google_logo.png';
import { ref, onValue, update} from "firebase/database";
import { db } from '../firebase/realtimeDatabase';
import { MdOutlineKeyboardBackspace, MdClose } from "react-icons/md";


const AuthModal = ({ show, handleClose , updateAuthState, uid}) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleEmailSubmit = () => {
    setCarouselIndex(1);
  };

  const handleBack = () => {
    setCarouselIndex(carouselIndex-1);
  };
  const handleAuth = async () => {
    try {
      if (isSignUp) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(userCredential.user);
        setMessage("Verification email sent. Please check your inbox (and spam folder).");
        setCarouselIndex(2); // Move to the next slide

      } else {
        await signInWithEmailAndPassword(auth, email, password);
        setMessage("User signed in successfully.");
        handleClose();
        setCarouselIndex(0);

      }
    } catch (error) {
      setError(error.message);
    }
  };


  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    if (uid == null){
      try {
        const result = await signInWithPopup(auth, provider).then((result) => {
          const user = result.user;
          const docRef = ref(db, 'users/' + user.uid + '/userInfo');
            onValue(docRef, (snapshot) => {
              if(!snapshot.exists()){
                console.log("not exist")
                update(ref(db, 'users/' + user.uid +'/userInfo'), {
                  username: user.email,
                  credits: 3,
                  subscribed: false,
                  subscriptionType: null,
                  subscriptionCancelled: false,
                  subscriptionEnd: null
                });
              }
              else{
                console.log("exist")
              }
            });


        });
        // The signed-in user info
      } catch (error) {
        console.error("Error during sign-in: ", error);
      }
    }
    else{
    try {

    linkWithPopup(auth.currentUser, provider).then((result) => {
      // Accounts successfully linked.
      const user = result.user;

      update(ref(db, 'users/' + auth.currentUser.uid +'/userInfo'), {
        username: user.email,
        credits: 3,
        subscribed: false,
        subscriptionType: null,
        subscriptionCancelled: false,
        subscriptionEnd: null
      });
      updateAuthState(true);
      handleClose();
      setCarouselIndex(0);

      // ...
    }).catch((error) => {
      try{
      const credential = GoogleAuthProvider.credentialFromError(error);
      signInWithCredential(auth, credential)

      }
      catch{

      }
      updateAuthState(true);
      handleClose();
      setCarouselIndex(0);
      // Handle Errors here.
      // ...
    });

  } catch (error) {
    console.error('Error signing in with Google:', error.message);
  }}

  };


  const handleSelect = (selectedIndex) => {
    setCarouselIndex(selectedIndex);
  };

  return (

    <Modal show={show}
     onHide={handleClose}
     aria-labelledby="contained-modal-title-vcenter"
     className='auth-modal'
     centered
     >
          <Modal.Header className="modal_login_header" >
            <div style={{display:"flex", flexDirection:"row"}}>
          {carouselIndex===1 && <div className='auth-back' onClick={handleBack} ><MdOutlineKeyboardBackspace size={24}/>
        </div> } <div className='auth-close' onClick={handleClose} ><MdClose size={24}/></div></div>
          </Modal.Header>

    <Modal.Body>



     <Carousel activeIndex={carouselIndex} onSelect={handleSelect} interval={null} controls={false} indicators={false}>
     <Carousel.Item>

            <Container>
      <Row><div className="text-center">
              <h1 style={{color: '#e4dede', fontSize: '3rem'}}>Welcome</h1>
            </div></Row>
      <Row>
      <Col xs={12} md={{ span: 10, offset: 1}} className='mb-5 mt-1'>
      <div className="mt-5">
              <Button variant="outline-light " size='xxl' onClick={handleGoogleSignIn}>
              <img src={googleLogo} alt="Google Logo" style={{ width: '2rem', marginRight: '1rem' }} />
                Sign In with Google
              </Button>
            </div>
            </Col>

      </Row>
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1}} >
          <Form >

          <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="email"
                size="lg"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div style={{height:'1rem'}}/>
            </Form.Group>

          </Form>
        </Col>
        <Col className='text-center'>
          <div className="mt-3">
            <Button variant="outline-info" size='lg' onClick={handleEmailSubmit}>
              Continue with Email
            </Button>
            </div>
        </Col>

      </Row>

    </Container>

          </Carousel.Item>

          <Carousel.Item>
            <Container>
            <Row><div className="text-center mt-3">
              <h1 style={{color: '#e4dede', fontSize: '2.5rem'}}>{isSignUp ? "Sign Up" : "Log In"}</h1>
            </div></Row>

                <Row>
                    <Col xs={12} md={{ span: 10, offset: 1}} className='mb-5 mt-1'>
                        <div className='mt-5'></div>
            <Form >
              <Form.Group className="mb-3" controlId="formBasicEmail2">
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  size="lg"

                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <div className='mb-2'>
              {error && <p className="mt-2 text-danger">{error}</p>}
              </div>
              <div className='text-end mt-3'>
              <Button variant="outline-info" size='lg' onClick={handleAuth}>
                {isSignUp ? "Sign Up" : "Log In"}

              </Button>
              </div>
              <div className="text-end mt-4">
                  <Form.Text onClick={() => setIsSignUp(!isSignUp)} style={{ cursor: 'pointer', color: '#FFC96F', fontSize: '1.2rem'}}>
                {isSignUp ? "Already have an account? Log In" : "Don't have an account? Sign Up"}
              </Form.Text></div>

            </Form>
            </Col>
            </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <div className='text-center mt-3' style={{display: 'flex', padding: '7rem'}}>
            <p style={{color: '#e4dede'}}>{message}</p>
            </div>
          </Carousel.Item>
    </Carousel>

      </Modal.Body>
    </Modal>
  );
};

export default AuthModal;
