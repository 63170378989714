import Accordion from 'react-bootstrap/Accordion'
import { Card, Spinner } from 'react-bootstrap';
import { BiError } from "react-icons/bi";

export default function GraphParser({ graphPng, isFinished, isFailed }) {
      const BlinkingText = ({ text }) => {
            return (
                  <div className="blinking-text">
                    <div className="blinking-content">
                      {text}
                      <span className="blinking-dots"></span>
                    </div>
                  </div>
                );
          };


          if (!isFinished) {
            // When isFinished is false, show the blinking text "Solving"
            return <BlinkingText text="Drawing graph" />;
          }

          if (isFinished && isFailed) {
            // When isFinished is true and isFailed is true, return nothing
            return null;
          }


  return (
    <div>
<img src={graphPng} alt="Generated Plot" style={{ maxWidth: '50rem', width: '100%' }} />
</div>
  );
}