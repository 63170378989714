import './App.css';
import Chats from './pages/chats';
import { MdOutlineQuestionMark } from "react-icons/md";
import { auth, analytics } from './firebase/firebaseAuth';
import { useState, useEffect, useRef } from 'react';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { ref, set, onValue, push, update } from "firebase/database";
import { db } from './firebase/realtimeDatabase';
import { BrowserRouter as Router, Routes, Route ,useLocation } from 'react-router-dom';
import CancelPage from './pages/cancelPage';
import SuccessPage from './pages/successPage';
import Account from './pages/acccount';
import History from './pages/history';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import NavBarMenu from './components/navBarComponent';
import TermsAndConditions from './pages/termsAndConditions';
import Privacy from './pages/privacy';
import { Link } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import ContactUs from './pages/conctactUs';
import { Spinner } from 'react-bootstrap';


function App() {
  const [user, setUser] = useState(null);
  const [userAvatar, setUserAvatar] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLogged, setLogState] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [subscriptionShow, setSubscriptionShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const creditsRef = useRef(2);
  const [credits, setCredits] = useState(creditsRef.current);


  const [userUID, setUserUID] = useState(null);


const direction = "end";

const InfoMenu = (
  <MdOutlineQuestionMark size={16}
  />
)

const updateModalState = (newValue) => {
  setModalShow(newValue);

};

const updateSubscriptionState = (newValue) => {
  setSubscriptionShow(newValue);

};

const resetCredits = async (newValue) => {
  creditsRef.current = newValue;
  setCredits(newValue);

}

const updateCredits = async (newValue, _userUID) => {
  creditsRef.current = newValue;
  setCredits(newValue);
  update(ref(db, `users/${_userUID}/userInfo`), ({credits: newValue}))

}

const createUser = (userId, user, credits) => {

  // Set user data under their user ID folder
  set(ref(db, 'users/' + userId +'/userInfo'), {
    username: user,
    credits: credits,
    subscribed: false,
    subscriptionType: null,
    subscriptionCancelled: false,
    subscriptionEnd: null

  });

};

const updateAuthState = (newValue) => {
  setLogState(newValue);

};

const handleAnonymousSignIn = () => signInAnonymously(auth)
    .then(() => {
      setUserUID(auth.currentUser.uid);
      setUser("anonymous");
      setCredits(creditsRef.current);
      setIsSubscribed(false);
      createUser(auth.currentUser.uid, "anonymous", creditsRef.current)



    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    });

useEffect(() => {
    const unSubscribeAuth = onAuthStateChanged(auth,
       async authenticatedUser => {
        if (authenticatedUser){
          const docRef = ref(db, 'users/' + authenticatedUser.uid + '/userInfo');
          await onValue(docRef, (snapshot) => {
            if(snapshot.exists()){
              const data = snapshot.val();
              if(data.username || (authenticatedUser.email && authenticatedUser.emailVerified)){
                creditsRef.current = data.credits;
                setCredits(data.credits);
                setIsSubscribed(data.subscribed);
            }}
          });
          setUser(authenticatedUser.email);
          setUserUID(authenticatedUser.uid);
          if (authenticatedUser.isAnonymous || !authenticatedUser.emailVerified){
            setLogState(false);
          }
          else{
            setLogState(true);
            setModalShow(false);
            setUserAvatar(authenticatedUser.providerData[0].photoURL);
          }
        }
        else{
          //handleAnonymousSignIn();
          setLogState(false);
        }
       })
      return unSubscribeAuth;

  }, [userUID, isLogged, userAvatar, isSubscribed]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000); // Replace with your actual loading logic
}, [userUID, isLogged, userAvatar, isSubscribed]);
  const pathsWithSidebar = ['/', '/history', '/account'];
  const shouldShowSidebar = pathsWithSidebar.includes(location.pathname);


  return (
      <div style={{ display: 'flex', justifyContent: 'center',}}>
        {loading ? (

                 <div className="spinnerHeader" style={{alignSelf:"center", fontSize:"1.7rem"}} >
                 <Spinner animation="border" variant="primary" />{'   '} initalizing
             </div>
            ) : (
                <>
                    {shouldShowSidebar && <NavBarMenu isMobile={isMobile} subscriptionShow={subscriptionShow} updateSubscriptionState={updateSubscriptionState} modalShow={modalShow} updateModalState={updateModalState} updateAuthState={updateAuthState} isLogged={isLogged} user={user} userAvatar={userAvatar} resetCredits={resetCredits} uid={userUID} isSubscribed={isSubscribed} handleAnonymousSignIn={handleAnonymousSignIn}></NavBarMenu>}
        <Routes>

          <Route path="/" element={
          <Chats userUID={userUID} isMobile={isMobile} credits={credits} updateCredits={updateCredits} modalShow={modalShow} updateModalState={updateModalState} updateAuthState={updateAuthState} isSubscribed={isSubscribed} isLogged={isLogged} subscriptionShow={subscriptionShow} updateSubscriptionState={updateSubscriptionState} handleAnonymousSignIn={handleAnonymousSignIn}/>
          } />
          <Route path="/history" element={<History userUID={userUID}/>} />
          <Route path="/account" element={<Account resetCredits={resetCredits} handleAnonymousSignIn={handleAnonymousSignIn}/>} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/cancel" element={<CancelPage />} />

        </Routes>

        {shouldShowSidebar &&
        (<div className='term-cond'>
          <DropdownButton className='dropdown-button-info'
              as={ButtonGroup}
              key={direction}
              id={`dropdown-button-drop-${direction}`}
              drop={direction}
              variant="secondary"
              size='lg'


              title={InfoMenu}
            >
              <Dropdown.Item as="span">
              <Link to="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="dropdown-item">
            Terms and Conditions
          </Link>


            </Dropdown.Item>
            <Dropdown.Item as="span">
            <Link to="/privacy" target="_blank" rel="noopener noreferrer" className="dropdown-item">
            Privacy
          </Link>
            </Dropdown.Item>

            <Dropdown.Item as="span">
            <Link to="/contact-us" target="_blank" rel="noopener noreferrer" className="dropdown-item">
            Contact Us
          </Link>
          </Dropdown.Item>
                            </DropdownButton>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default App;